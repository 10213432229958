// src/pages/Home.js
import React from 'react';

function Home() {
  return (
    <div>
      <h1
      style={{ color: 'White', textAlign: 'center', fontSize: '1rem', marginTop: '2rem' }}> Home</h1>
    </div>
  );
}

export default Home;
