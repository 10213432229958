// src/pages/Services.js
import React from 'react';


function Services() {
  return (
    <div>
      <h1
      style={{ color: 'White', textAlign: 'center', fontSize: '1rem', marginTop: '2rem' }}>Services</h1>
    </div>
  );
}

export default Services;
