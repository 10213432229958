// src/pages/Contact.js
import React from 'react';

function Contact() {
  return (
    <div>
      <h1
      style={{ color: 'White', textAlign: 'center', fontSize: '1rem', marginTop: '2rem' }}>Contact</h1>
    </div>
  );
}

export default Contact;
