// src/pages/Product.js
import React from 'react';

function Product() {
  return (
    <div>
      <h1
      style={{ color: 'White', textAlign: 'center', fontSize: '1rem', marginTop: '2rem' }}>Product</h1>
    </div>
  );
}

export default Product;
